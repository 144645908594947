<template>
  <div id="orthodontic_systems">
    <mainMenu></mainMenu>
    <div class="headerPreview">
      <div class="wrapper">
        <div class="sectionInfo">
          <h1 class="sectionTitle">
            3D файлы для изготовления <br />
            ортодонтических <br />
            систем
          </h1>
          <button
            type="button"
            class="btnAbout"
            @click="isAbout = !isAbout"
            v-html="!isAbout ? 'Читать о библиотеке' : '< Вернуться'"
          ></button>
        </div>
      </div>
    </div>
    <div class="orthodontic_systems_view" v-if="!isAbout">
      <div class="orthodontic_systems_view-goBack">
        <router-link :to="{ name: 'library3d' }">
          <icon name="chevron-left" scale="1.8" class="mr-50"></icon>
          <span>Вернуться к 3D библиотекам</span>
        </router-link>
      </div>
      <div class="orthodontic_systems_view_wrapper">
        <router-link
          :to="{ name: 'orthodonticSystemsItem', params: { styleId: style._id } }"
          v-for="style in stylesList"
          :key="style.id"
          class="toothStyle"
        >
          <img :src="style.logo" alt="" />
        </router-link>
      </div>
      <infinite-loading @infinite="getStylesList">
        <span slot="no-more">
          <span v-if="!isLoggedIn && !isPremium">
            Для получения возможности скачивания всех библиотек в формате psd, pptx, stl и eoff Вам необходимо <br />
            <router-link :to="{ name: 'signin' }">зарегистрироваться</router-link> и приобрести премиум аккаунт
          </span>
          <span v-if="isLoggedIn && !isPremium">
            Для скачивания всех библиотек в формате psd, pptx, stl и eoff Вам необходимо приобрести
            <router-link :to="{ name: 'profile' }">премиум аккаунт</router-link>
          </span>
        </span>
      </infinite-loading>
    </div>
    <div id="orthodontic_systems_about" v-if="isAbout">
      <section id="orthodontic_systems_section_1">
        <div class="wrapper">
          <p class="orthodontic_systems-about">
            <span class="orthodontic_systems-about-accent">Библиотека ортодонтических 3D файлов</span>
            представляет собой набор разнообразных брекет-систем, аттачментов для работы с элайнерами и вспомогательных
            элементов. Данные файлы можно использовать при создании несъемных долговременных конструкций, применяемых
            при ортодонтическом лечении, для создания функциональных ретенционных пунктов, а так же удерживающих
            эластики и лигатуру элементов с использованием 3D технологий.
          </p>
        </div>
      </section>
      <section id="orthodontic_systems_section_2">
        <div class="wrapper">
          <p class="bracketsReview-title">
            <span class="bracketsReview-title-accent">3D брекеты</span> позволяют изготовить несъемный протез способный
            удерживать ортодонтическую дугу, используя стандартную лигатуру. Представленные библиотеки брекетов позволят
            изготовить протез из пластка, металла, керамики или диоксида циркония методом селективного спекания
            металлов, 3D фрезерования, а так же литья или прессования фрезерованных или распечатанных выжигаемых
            конструкций.
          </p>
          <div class="bracketsReview-preview">
            <div class="bracketsReview-type">
              <img src="./../../assets/images/library3d/bracketsReview_1.jpg" alt=" " class="bracketsReview-img" />
              <p class="bracketsReview-about text-purple">3D Брекеты под пластик</p>
            </div>
            <div class="bracketsReview-type">
              <img src="./../../assets/images/library3d/bracketsReview_2.jpg" alt=" " class="bracketsReview-img" />
              <p class="bracketsReview-about text-blue">3D Брекеты под металл</p>
            </div>
            <div class="bracketsReview-type">
              <img src="./../../assets/images/library3d/bracketsReview_3.jpg" alt=" " class="bracketsReview-img" />
              <p class="bracketsReview-about text-purple">3D Брекеты под керамику и цирконий</p>
            </div>
          </div>
        </div>
      </section>
      <section id="orthodontic_systems_section_3">
        <div class="wrapper">
          <p class="bracketsInnovation-about">
            Данный подход является инновационным и незаменимым для многих случаев ортодонтического лечения, связанных с
            адентией или реставрацией коронковой части зуба.
          </p>
          <div class="bracketsInnovation-container">
            <img
              src="./../../assets/images/library3d/bracketsInnovation.png"
              alt=""
              class="bracketsInnovation-container-img"
            />
          </div>
        </div>
      </section>
      <section id="orthodontic_systems_section_4">
        <div class="wrapper">
          <p class="orthodonticButtons-about">
            <span class="orthodonticButtons-about-accent">3D Ортодонтические кнопки</span>
            позволяют создать надежные пункты для фиксации эластиков, эластических цепочек и других вспомогательных
            ортодонтических элементов.
          </p>
          <div class="orthodonticButtons-content">
            <div class="orthodonticButtons-content-container">
              <img
                src="./../../assets/images/library3d/orthodonticButton_1.png"
                alt=""
                class="orthodonticButtons-content-container-img"
              />
            </div>
            <div class="orthodonticButtons-content-container">
              <img
                src="./../../assets/images/library3d/orthodonticButton_2.png"
                alt=""
                class="orthodonticButtons-content-container-img"
              />
            </div>
          </div>
        </div>
      </section>
      <section id="orthodontic_systems_section_5">
        <div class="wrapper">
          <p class="elainers-about">
            <span class="elainers-about-accent">3D Аттачменты</span> для проектирования систем элайнеров.
          </p>
          <div class="eliners-container">
            <img src="./../../assets/images/library3d/elainers_preview_1.png" class="eliners-container-img" alt="" />
            <img src="./../../assets/images/library3d/elainers_preview_2.png" class="eliners-container-img" alt="" />
          </div>
          <p class="elainers-about">
            Библиотека состоит из 14 - и аттачментов в формате STL, позволяющих создавать активатры для более
            эфективного передвижения зубов выравнивающими капами.
          </p>
          <div class="eliners-container">
            <img src="./../../assets/images/library3d/elainers_preview_3.png" class="eliners-container-img" alt="" />
          </div>
        </div>
      </section>
      <section id="orthodontic_systems_section_6">
        <div class="wrapper">
          <div class="demoVideoContent">
            <div class="demoVideoContent-container">
              <div class="demoVideoContent-container-content">
                <iframe
                  width="100%"
                  height="auto"
                  src="https://www.youtube.com/embed/1eHV-fLOevQ"
                  frameborder="0"
                  allow="autoplay; encrypted-media"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <div class="demoVideoContent-container">
              <div class="demoVideoContent-container-content">
                <iframe
                  width="100%"
                  height="auto"
                  src="https://www.youtube.com/embed/gHhV4Lxf7vM"
                  frameborder="0"
                  allow="autoplay; encrypted-media"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <mainFooter></mainFooter>
  </div>
</template>

<script>
import mainMenu from "./../mainMenu/mainMenu.vue";
import mainFooter from "./../mainFooter/mainFooter.vue";

import InfiniteLoading from "vue-infinite-loading";

export default {
  name: "OrthodonticSystems",
  components: { mainMenu, mainFooter, InfiniteLoading },
  props: ["is_previewer"],
  data() {
    return {
      isPremium: false,
      isAbout: false,
      page: 1,
      endpoint: "orthodontic_systems/",
      endpointTemplate: "orthodontic_systems_template/",
      stylesList: [],

      fileDownload: {
        startTemlatePPTX: "",
        startTemlatePPTXPSD: "",
      },
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
  methods: {
    getProfile() {
      this.$http.post(this.profileStatusEndpoint).then((response) => {
        if (response.data.isPremium) {
          this.isPremium = true;
        } else {
          this.isPremium = false;
        }
      });
    },
    getFileDownload() {
      this.$http.get(this.endpointTemplate).then((response) => {
        if (response.data.success) {
          this.fileDownload.startTemlatePPTX = response.data.links.startTemlatePPTX;
          this.fileDownload.startTemlatePPTXPSD = response.data.links.startTemlatePPTXPSD;
        }
      });
    },
    getStylesList($state) {
      var options = {
        params: { page: this.page },
      };
      this.$http.get(this.endpoint, options).then((response) => {
        // console.log(response);
        if (response.data.orthodonticSystemsList.length) {
          this.stylesList = this.stylesList.concat(response.data.orthodonticSystemsList);
          this.page++;
          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },
  },
  created() {
    this.getProfile();
    this.getFileDownload();
  },
};
</script>

<style lang="sass">
#orthodontic_systems
	display: flex
	flex-direction: column
	min-height: calc(100vh - 155px)
	& .headerPreview
		display: flex
		min-height: 360px
		align-items: center
		padding: 0 2rem
		background: url('./../../assets/images/library3d/braketBG.jpg')
		background-size: cover
		background-position: center center
		& .sectionInfo
			width: fit-content
			@media screen and (max-width: 426px)
				display: flex
				flex-direction: column
				align-items: center
				width: 100%
				text-align: center
			& .sectionTitle
				font-weight: lighter
				color: #fff
				padding-bottom: 1.5rem
				margin: 0 0 1.5rem
			& .sectionDescription
				color: #fff
				font-size: 1.6rem
				margin: 0 0 1.5rem
			& .btnAbout
				display: block
				width: 100%
				background: #ff00c0
				color: #fff
				padding: 0.5rem 2rem
				border: 1px solid transparent
				margin-bottom: 1rem
				cursor: pointer
			& .btnShareLink
				display: block
				width: 100%
				background: #2c7802
				color: #fff
				padding: 0.5rem 2rem
				border: 1px solid transparent
				cursor: pointer
				transition: all 0.5s
				&:hover
					background: #579933
			& .dynamicSrcBar
				display: flex
				height: 36px
				& input
					display: block
					width: 100%
					border: 1px solid #333
					background: #333
					color: #fff
				& .btnModalWindow
					display: block
					border: 1px solid transparent
					color: #ffffff
					background-color: #2c7802
					padding: 0 0.25rem
					cursor: pointer
					transition: all 0.5s
					&:hover
						background: #579933

	.orthodontic_systems_view
		background: #111111
		padding-bottom: 2rem
		min-height: 50vh
		& .orthodontic_systems_view-goBack
			margin: 0.5rem auto
			max-width: 1024px
			width: 100%
			& a
				display: inline-flex
				align-items: center
				padding: 0.5rem 0
				font-size: 18px
				color: #a0a0a0
				transition: all 0.5s
				&:hover
					color: #e0e0e0
		.orthodontic_systems_view_wrapper
			display: block
			max-width: 1024px
			width: 100%
			margin: 0 auto
			.toothStyle
				display: inline-block
				width: calc(100% / 5)
				border: 2px solid #111
				margin-bottom: -4px
				@media screen and (max-width: 769px)
					width: calc(100% / 4)
				@media screen and (max-width: 600px)
					width: calc(100% / 3)
				@media screen and (max-width: 426px)
					width: calc(100% / 2)
				&:hover
					border: 2px solid #444

				& img
					display: block
					width: 100%
					height: auto

#orthodontic_systems_about
	font-family: 'Roboto'
	letter-spacing: 0rem
	color: #ffffff

#orthodontic_systems_section_1
	padding: 2rem 1rem
	background-color: #25292b
	& .orthodontic_systems-about
		line-height: 1.5rem
		font-weight: 300
		& .orthodontic_systems-about-accent
			color: #2adcde
			font-size: 1.5rem

#orthodontic_systems_section_2
	padding: 2rem 1rem
	background-color: #000000
	& .bracketsReview-title
		line-height: 1.5rem
		font-weight: 300
		& .bracketsReview-title-accent
			color: #dd5894
			font-size: 1.5rem
	& .bracketsReview-preview
		display: flex
		flex-wrap: wrap
		justify-content: center
		& .bracketsReview-type
			display: flex
			flex-direction: column
			justify-content: flex-start
			align-items: center
			padding: 0 1.5rem
			width: calc(100% / 3)
			@media screen and (max-width: 1024px)
				width: auto
			& .bracketsReview-img
				display: block
				width: 100%
				height: auto
				object-fit: contain
				max-width: 165px
    			margin: 1.5rem 0
			& .bracketsReview-about
				margin-top: 1rem
				font-family: 'Ropa Sans Pro SC Regular'
				font-size: 1.2rem
				text-align: center
				&.text-purple
					color: #ff00c0
				&.text-blue
					color: #27e0e7

#orthodontic_systems_section_3
	padding: 2rem 1rem
	background-color: #25292b
	& .bracketsInnovation-about
		line-height: 1.5rem
		font-weight: 300
		margin-bottom: 1.5rem
	& .bracketsInnovation-container
		display: block
		margin: 0 auto
		padding: 0 0.5rem
		max-width: 70%
		& .bracketsInnovation-container-img
			display: block
			width: 100%
			height: 100%
			object-fit: contain

#orthodontic_systems_section_4
	padding: 2rem 1rem
	background-color: #000000
	& .orthodonticButtons-about
		line-height: 1.5rem
		font-weight: 300
		margin-bottom: 1.5rem
		& .orthodonticButtons-about-accent
			color: #00ecff
			font-size: 2rem
	& .orthodonticButtons-content
		display: flex
		flex-wrap: wrap
		justify-content: center
		& .orthodonticButtons-content-container
			display: block
			padding: 0 2rem
			width: 50%
			@media screen and (max-width: 768px)
				width: 100%
			& .orthodonticButtons-content-container-img
				display: block
				width: 100%
				height: 100%
				object-fit: contain

#orthodontic_systems_section_5
	padding: 2rem 1rem
	background-color: #25292b
	& .elainers-about
		text-align: center
		font-weight: 300
		margin-bottom: 2rem
		& .elainers-about-accent
			font-family: 'Ropa Sans Pro SC Regular'
			font-size: 2rem
			color: #ff67ab
	& .eliners-container
		display: flex
		flex-wrap: nowrap
		justify-content: space-around
		margin: 1rem 2rem 2rem
		@media screen and (max-width: 768px)
			flex-direction: column
		& .eliners-container-img
			display: block
			object-fit: contain

#orthodontic_systems_section_6
	padding: 2rem 1rem 4rem
	background-color: #000000
	& .demoVideoContent
		display: flex
		flex-direction: column
		align-content: center
		justify-content: center
		& .demoVideoContent-container
			display: block
			box-sizing: borderbox
			& .demoVideoContent-container-title
				font-size: 1.8rem
				font-weight: 700
				text-align: center
				padding: 2.5rem 1rem
			& .demoVideoContent-container-content
				display: block
				position: relative
				padding-bottom: 56.20% //16:9
				padding-top: 25px
				height: 0
				border: 1px solid transparent
				& iframe
					position: absolute
					top: 0
					left: 0
					width: 100%
					height: 100%
</style>
